<template>
  <div class="cpt-service-header">
    <div class="basic">
      <!-- <img class="logo" src="~@/assets/logo.svg" alt="logo" /> -->
      <!-- <div class="title">无人机应用服务平台</div> -->
      <img class="logo" src="~@/components/newmenu/header/img/top.png" alt="logo" />
    </div>
    <div class="actions">
      <div class="actions-routes">
        <template v-for="(item, index) in route_list">
          <template v-if="item.children && item.children.length">
            <el-popover
              :key="index"
              placement="bottom"
              trigger="hover"
              popper-class="action-route-popover"
              width="140"
            >
              <div class="action-route-dropdown-list">
                <div
                  class="action-route-dropdown"
                  :class="`${child.path == $route.path ? 'active' : ''}`"
                  v-for="(child, index) in item.children"
                  :key="index"
                  v-view="{ sign: child.sign }"
                  @click="$router.push(child.path)"
                >
                  {{ child.name }}
                </div>
              </div>
              <div
                slot="reference"
                class="action-route"
                :class="`${
                  $route.path.indexOf(item.path) !== -1 ? 'active' : ''
                }`"
              >
                {{ item.name }}<i class="el-icon-caret-bottom"></i>
              </div>
            </el-popover>
          </template>
          <template v-else>
            <div
              :key="index"
              class="action-route"
              v-view="{ sign: item.sign }"
              :class="`${item.path == $route.path ? 'active' : ''}`"
              @click="$router.push(item.path)"
            >
              {{ item.name }}
            </div>
          </template>
        </template>
      </div>
      <div class="actions-info">
        <el-tooltip class="item" effect="dark" content="飞行检查" placement="bottom">
        <div
          class="action-item action-warning"
          @click="safefly1"
          v-view="{ sign: 'hn:safeFlight' }"
          style="margin-top:6px;text-align:center"
        >
          <div class="check-image iconfont icon-feiqianjiancha"></div>
          <!-- <div class="text">飞行检查</div> -->
        </div>
         </el-tooltip>
         <el-tooltip class="item" effect="dark" content="飞行检查" placement="bottom">
        <div
          class="action-item action-warning"
          @click="safefly"
          v-view="{ sign: 'hn:flightCheck' }"
          style="margin-top:6px;text-align:center"
        >
          <div class="safefly-image iconfont icon-anquanfeihang"></div>
          <!-- <div class="text">安全飞行</div> -->
        </div>
        </el-tooltip>
        <!-- <div class="action-item action-eamil" v-if="routeMessage">
          <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="action-route-popover"
            width="140"
          >
            <div class="action-route-dropdown-list">
              <template v-for="(item, index) in message_list">
                <div
                  class="action-route-dropdown"
                  :class="`${item.path == $route.path ? 'active' : ''}`"
                  :key="index"
                  @click="$router.push(item.path)"
                >
                  {{ item.name }}
                </div>
              </template>
            </div>

            <div slot="reference">
              <div class="messageIcon"></div>
              <div class="text">消息通知</div>
            </div>
          </el-popover>
        </div> -->
        <HeaderMessage :message_list="message_list" :contentState="'layout'"/>
        <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom">
        <div class="action-item action-user" @click="action">
          <!-- <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="action-route-popover"
            width="140"
          >
            <div class="action-route-dropdown-list">
              <div
                class="action-route-dropdown"
                :class="`${item.path == $route.path ? 'active' : ''}`"
                v-for="(item, index) in user_list"
                :key="index"
                @click="item.action ? item.action() : $router.push(item.path)"
              >
                {{ item.name }}
              </div>
            </div>
            <div slot="reference" class="imgIcon">
              <div>
                <div class="text">{{ user_info_hn.departmentName }}</div>
                <div class="text">{{ user_info_hn.name }}</div>
              </div>
              <div>
                <img style="width: 36px; height: 36px" :src="img" />
              </div>
              
            </div>
          </el-popover> -->

          <!-- <div class="action-route-dropdown-list">
              <div
                class="action-route-dropdown"
                :class="`${item.path == $route.path ? 'active' : ''}`"
                v-for="(item, index) in user_list"
                :key="index"
                @click="item.action ? item.action() : $router.push(item.path)"
              >
                {{ item.name }}
              </div>
            </div> -->
          <div slot="reference" class="imgIcon">
            <!-- <div style="height: 100%;margin-right: 5px;">
              <div class="text" style="margin-top: 5px">
                {{ user_info_hn.departmentName }}
              </div>
              <div class="text" style="margin-bottom: 10px">
                {{ user_info_hn.name }}
              </div>
            </div> -->
            <div>
              <div
                class="iconfont icon-gerenzhongxin1"
                style="
                  width: 36px;
                  height: 36px;
                  box-sizing: border-box;
                "
              ></div>
            </div>
          </div>
        </div>
      </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import img1 from "@/assets/home/headImg1.png";
import HeaderMessage from '@/components/dashboard/header-message'
export default {
  components: {HeaderMessage},
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    return {
      img: img1,
      route_list: [{ name: "首页", path: "/home" }],
      route_list1: [
        { name: "空域管理", path: "/platform/airspace" },
        { name: "航空航线", path: "/platform/airway" },
        {
          name: "全要素",
          path: "/platform/poi",
          children: [
            {
              name: "信息模式",
              path: "/platform/poi",
              sign: "hn:sky:totalFactor:message",
            },
            {
              name: "可视化模式",
              path: "/platform/visualization",
              sign: "hn:sky:totalFactor:visualization",
            },
          ],
        },
        { name: "三维模型", path: "/platform/model", sign: "hn:sky:model" },
        {
          name: "航空任务",
          path: "/platform/order/task",
          children: [
            // { name: "需求列表", path: "/platform/order/demand" },
            // { name: "任务列表", path: "/platform/order/task" },
          ],
        },
        {
          name: "数据看板",
          children: [],
        },
        // {
        //   name: "人员看板",
        //   path: "/platform/staff",
        //   sign: "hn:sky:personnelBoard",
        // },
        // { name: "设备看板", path: "/platform/device" },
        // { name: "AI视频", path: "/platform/aivideo", sign: "hn:sky:AIvideo" },
        // { name: "反制侦察", path: "/counteract" },
        // { name: "航空设备", path: "/platform/aeronautical" }
        // { name: "飞行作业", path: "/platform/flyWork" },
      ],
      message_list: [],
      message_list1: [
        { name: "预警消息", path: "/platform/message/warning", type: 0.5 },
        { name: "需求消息", path: "/platform/message/order", type: 60 },
        { name: "审批消息", path: "/platform/message/task", type: 70 },
      ],
      routeMessage: false,
      // user_list: [
      //   {
      //     name: "退出登录",
      //     action: () => {
      //       this.$confirm("确定要退出登录么?", "提示", {
      //         confirmButtonText: "确定",
      //         cancelButtonText: "取消",
      //         type: "warning",
      //       }).then(() => {
      //         this.handle_logout();
      //       });
      //     },
      //   },
      // ],
    };
  },
  created() {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    // if (permissionList.indexOf("hn:sky:basicResources:airspace") > -1) {
    //   this.route_list.push(this.route_list1[0]);
    // }
    // if (permissionList.indexOf("hawk:control:flightLine:flightLineList") > -1) {
    //   this.route_list.push(this.route_list1[1]);
    // }
    // if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:message") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:visualization") > -1
    // ) {
    //   this.route_list.push(this.route_list1[2]);
    // } 
    // else if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:message")
    // ) {
    //   this.route_list.push({
    //     name: "全要素",
    //     path: "/platform/poi",
    //     children: [{ name: "信息模式", path: "/platform/poi" }],
    //   });
    // } else if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:visualization") > -1
    // ) {
    //   this.route_list.push({
    //     name: "全要素",
    //     path: "/platform/poi",
    //     children: [{ name: "可视化模式", path: "/platform/visualization" }],
    //   });
    // }

    // if (permissionList.indexOf("hn:sky:model") > -1) {
    //   this.route_list.push(this.route_list1[3]);
    // }
    // if (permissionList.indexOf("hn:task-web") > -1) {
    //   this.route_list.push(this.route_list1[4]);
    // }
    // if (permissionList.indexOf("hn:sky:personnelBoard") > -1 || permissionList.indexOf("hn:sky:deviceBoard") > -1) {
    //   if(permissionList.indexOf("hn:sky:personnelBoard") > -1){
    //     this.route_list1[5].children.push(
    //       {
    //         name: "人员看板",
    //         path: "/platform/staff",
    //         sign: "hn:sky:personnelBoard",
    //       }
    //     )
    //   }
    //   if(permissionList.indexOf("hn:sky:deviceBoard") > -1){
    //     this.route_list1[5].children.push(
    //       {
    //           name: "设备看板",
    //           path: "/platform/device",
    //           sign: "hn:sky:deviceBoard",
    //         }
    //     )
    //   }
    //   this.route_list.push(this.route_list1[5]);
    // }

    if (permissionList.indexOf("hn:sky:AIvideo") > -1) {
      // this.route_list.push(this.route_list1[6]);
    }
    if (permissionList.indexOf("hn:message") > -1) {
      this.routeMessage = true;
    }
    if(permissionList.indexOf("hn:sky:historyWaringMessage") > -1) {
      this.message_list.push({ name: "历史预警", path: "/platform/message/warning", type: 0.5 })
    }
    if(permissionList.indexOf("hn:sky:needMessage") > -1) {
      this.message_list.push({ name: "需求消息", path: "/platform/message/order", type: 60 })
    }
    if(permissionList.indexOf("hn:sky:approvalMessage") > -1) {
      this.message_list.push({ name: "审批消息", path: "/platform/message/task", type: 70 })
    }
  },
  methods: {
    ...mapActions("user", ["Logout"]),
    handle_logout() {
      this.Logout();
    },
    hidden() {
      const path = this.$route.fullPath;
      if (path === "/platform/order/visual?mode=1") {
        this.route_list = [
          { name: "首页", path: "/home" },
          { name: "航空设备", path: "/platform/airspace" },
        ];
      }
    },
    safefly() {
      this.$emit("sadeflyshow", true);
    },
    safefly1() {
      this.$emit("sadeflyshow2", true);
    },
    action() {
      this.$confirm("确定要退出登录么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handle_logout();
      });
    },
  },

  mounted() {
    this.hidden();
  },
};
</script>

<style lang="scss">
.action-route-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-route-dropdown-list {
    .action-route-dropdown {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      &:hover,
      &.active {
        color: #2cacff;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.cpt-service-header {
  height: 72px;
  background: left center url(~@/assets/platform/header-bg.png) no-repeat;
  position: fixed;
  top: 13px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  justify-content: space-between;
  flex-shrink: 0;
  .basic {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .logo {
      // width: 78px;
      // height: 78px;
      // margin-left: -10px;
      // margin-right: 6px;
       width: 372px;
      height: 100%;
      margin-left: -10px;
      margin-right: 6px;
    }
    .title {
      font-family: PangMenZhengDao;
      font-size: 36px;
      color: #e0e6f2;
      letter-spacing: 4px;
      font-weight: 400;
      background-image: linear-gradient(180deg, #ffffff 0%, #428fde 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .actions-routes {
      display: flex;
      align-items: center;
      // margin-right: 67px;
      box-sizing: border-box;
      .action-route-popover {
        margin-right: 16px;
        &:last-child {
          margin-right: 0px;
        }
        .action-route-dropdown {
          border: 1px solid #488cff;
        }
      }
      .action-route {
        width: 124px;
        height: 44px;
        background: center center url(~@/assets/platform/route-btn.png)
          no-repeat;
        background-size: 100% 100%;
        color: #9fc4ff;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        box-sizing: border-box;
        letter-spacing: 1px;
        margin-left: 5px;
        .el-icon-caret-bottom {
          margin-top: -3px;
          transition: 0.3s;
        }
        &:hover {
          .el-icon-caret-bottom {
            transform: rotate(180deg);
          }
        }
        &:hover,
        &.active {
          background: center center url(~@/assets/platform/route-btn-active.png)
            no-repeat;
        }
      }
    }
    .actions-info {
      // margin-left: 100px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-left: 24px;
      .action-item {
        width: 50px;
        height: 36px;
        /* border: 1px solid red; */
        margin-right: 6px;
        box-sizing: border-box;
        // display: flex;
        // align-items: center;
        justify-content: center;
        background: center center #103a78;
        // url(~@/assets/platform/header-icon-bg.png) no-repeat;
        // background-size: 100% 100%;
        transition: 0.3s;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
        // &:hover {
        //   background: center center #06214b
        //   url(~@/assets/platform/header-icon-bg.png) no-repeat;
        // }
        .iconfont {
          // color: #5abeff;
          color: #fff;
          font-size: 25px;
        }
        .text {
          font-size: 12px;
          color: #9fc4ff;
          width: 76px;
          text-align: left;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // margin-top: 4px;
        }
      }
      .action-user {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
    .safefly-image {
      width: 50px;
      height: 24px;
      // background-image: url(~@/assets/platform/security.png);
      // background-repeat: no-repeat;
      // background-position: center;
    }
    .check-image {
      width: 50px;
      height: 24px;
      // background-image: url(~@/assets/platform/inspect.png);
      // background-repeat: no-repeat;
      // background-position: center;
    }
    .messageIcon {
      width: 50px;
      height: 24px;
      background-image: url(~@/assets/platform/message.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .imgIcon {
      // width: 121px;
      height: 37px;
      padding-top: 3px;
      padding-left: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // background-image: url("~@/assets/home/avatarbg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .text {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #daf4ff;
        text-align: right !important;
        margin-top: 0px;
        // transform: scale(0.833);
        // -webkit-transform: scale(0.833);
      }
    }
  }
}
.iconfont {
  cursor: pointer;
  font-size: 18px;
  color: #9fc4ff;
  margin-right: 4px;
  &:last-child {
    margin-right: 0px;
  }
}
</style>
