<template>
  <div class="cpt-platform-layout">
    <Header @sadeflyshow ='sadeflyshow' @sadeflyshow2 = 'sadefly2show' />
    <div class="content">
      <router-view />
      <!-- <div class="cesium-container" v-if="mode == 1">
        <div class="cesium-wrap">
          <CesiumLayer />
        </div>
      </div> -->
    </div>

    <el-dialog
      v-view="{sign:'hn:sky:popOut'}"
      title="需求清单通知"
      :visible.sync="visible.notice"
      v-if="list.length > 0"
      width="1110px"
    >
      <div class="task-list-notice-wrap">
        <div class="map-box"></div>
        <div class="props-box">
          <div class="prop-field">
            <div class="prop-field-k">任务名称：</div>
            <div class="prop-field-v">{{ list[index].taskTitle }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">任务类型：</div>
            <div class="prop-field-v">{{ list[index].taskCateName }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">场景类型</div>
            <div class="prop-field-v">{{ list[index].taskTypeName }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">配置AI</div>
            <div class="prop-field-v">{{ list[index].ais }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">飞行时间</div>
            <div class="prop-field-v">
              {{ list[index].startedAt + "-" + list[index].endAt }}
            </div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">作业位置</div>
            <div class="prop-field-v">
              {{ list[index].origin + "-" + list[index].destination }}
            </div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">需求描述</div>
            <div class="prop-field-v">{{ list[index].remark }}</div>
          </div>
        </div>
        <div class="props-box">
          <div class="prop-field">
            <div class="prop-field-k">配备人员</div>
            <div class="prop-field-v">{{ list[index].providePersonCount }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">配备无人机</div>
            <div class="prop-field-v">
              {{ list[index].deviceType.map((item) => item.name).join("、") }}
            </div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">匹配挂载</div>
            <div class="prop-field-v">
              {{ list[index].mountType.map((item) => item.name).join("、") }}
            </div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">配备车辆</div>
            <div class="prop-field-v">{{ list[index].provideCarCount }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">作业天数</div>
            <div class="prop-field-v">{{ list[index].workDayCount }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">需求单位</div>
            <div class="prop-field-v">{{ list[index].organizationName }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">对接人</div>
            <div class="prop-field-v">{{ list[index].userName }}</div>
          </div>
          <div class="prop-field">
            <div class="prop-field-k">联系电话</div>
            <div class="prop-field-v">{{ list[index].phone }}</div>
          </div>
        </div>
      </div>
      <div slot="footer" style="display: flex; justify-content: center">
        <div class="linear-btn" style="margin-right: 24px" @click="del">
          上一份
        </div>
        <div class="linear-btn" style="margin-right: 24px" @click="seeLater">
          待会再看
        </div>
        <div class="linear-btn" style="margin-right: 24px" @click="doItNow(list[index].id)">
          需求处理
        </div>
        <div class="linear-btn" @click="add">下一份</div>
      </div>
    </el-dialog>

    <SafeFly 
      :securityFlyVisible="FlyVisible"
      :clear="() => FlyVisible = false"
    />

    <!-- <CheckSafe  去除飞行检查清单  11.18
      :checkVisibles="checkVisible"
      :clear="() => checkVisible = false"
      
    ></CheckSafe> -->

  </div>
</template>

<script>
let ws;
import { mapGetters } from "vuex";
import Header from "@/components/platform/header";
import CesiumLayer from "@/components/cesium-layer";
import SafeFly from "@/components/header-right/safeFly.vue";
import API from "@/api";
// import CheckSafe from '@/components/header-right/check_safe.vue';

const { VUE_APP_WS_URL: WS_URL } = process.env;
export default {
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  components: { Header, CesiumLayer , SafeFly  },
  data() {
    return {
      FlyVisible: false,
      securityFlyVisible: false,
      checkVisible:false,
      mode: 2,
      visible: {
        notice: false,
      },
      list: [],
      index: 0,
    };
  },
  watch: {
    $route: {
      handler(value) {
        const { query } = value;
        if (query && query.mode != this.mode) {
          this.mode = query.mode;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  provide() {
    return {
      toggle_mode: (mode) => {
        const { path, query } = this.$route;
        if (query && query.mode != mode) {
          this.$router.replace({
            path: path,
            query: {
              ...query,
              mode,
            },
          });
        } else if (mode === null) {
          delete query.mode;
          this.$router.replace({
            path: path,
            query: { ...query },
          });
        }
      },
    };
  },
  methods: {
    init_ws() {
      let { user_info_hn } = this;
      let username = user_info_hn.username;
      let token = user_info_hn["mmc-identity"] + user_info_hn["FLYINGSESSIONID"];
      let appId = user_info_hn.appid;
      ws = new WebSocket(WS_URL);
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId,
          })
        );
      };

      ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.type === 345) {
          this.index = data.data.list.length-1;
          this.list = data.data.list;
          this.visible.notice = true;
        }
      };
      ws.onclose = () => {
        
      };
    },
    add() {
      if (this.index < this.list.length - 1) {
        this.index++;
      }
    },
    del() {
      if (this.index > 0) {
        this.index--;
      }
    },
    removeMsg() {
      let res = API.TASK.removeMsg({
        id: this.list[index].id,
      });
    },
    seeLater() { // 待会再看
      this.visible.notice = false
    },
    doItNow(id) { //立即处理
      this.visible.notice = false
      this.$router.push('/platform/order/handle/'+id)
    },
    sadeflyshow(data){
      
      this.FlyVisible = data
    },
    sadefly2show(data){
      this.checkVisible = data
    }
  },
  created() {
    // this.init_ws();
  },
  destroyed() {
    if(ws) {
      ws.onclose();
    }
  },
};
</script>

<style lang="scss">
.linear-btn {
  width: 98px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}

.linear-form {
  box-sizing: border-box;
  .linear-fields {
    border: 1px solid #27526e;
    max-height: 100px;
    overflow: auto;
    box-sizing: border-box;
    padding: 4px;
    .fields-icon {
      width: 40px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #009aff;
      margin-left: 5px;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      flex-shrink: 0;
      &.icon-delete {
        border: 1px solid tomato;
        color: tomato;
      }
    }
  }
  .el-form-item {
    height: 36px;
    margin-bottom: 0;
    box-sizing: border-box;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    .el-form-item__label {
      line-height: 32px;
      box-sizing: border-box;
      font-size: 16px;
      color: #9fc4ff;
      letter-spacing: 0;
      text-align: right;
      font-weight: 400;
    }
    .el-form-item__content {
      line-height: 1;
      box-sizing: border-box;

      .el-input-number {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        height: 36px;
        line-height: 36px;
        .el-input-number__decrease {
          box-sizing: border-box;
          background: transparent;
          border-right: 1px solid #009aff;
          color: #fff;
          height: 35px;
        }
        .el-input-number__increase {
          background: transparent;
          border-left: 1px solid #009aff;
          color: #fff;
          box-sizing: border-box;
          height: 35px;
        }
      }

      .el-textarea {
        textarea {
          box-sizing: border-box;
          background: rgba(12, 34, 67, 0.5);
          background: transparent;
          border: 1px solid #009aff;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
          width: 132px;
        }
      }
      .el-date-editor {
        background: transparent;
        border: none;
        padding: 0;
        height: 36px;
        border: 1px solid #009aff;
        box-sizing: border-box;
        border-radius: 0;
        width: 300px;
        background: rgba(12, 34, 67, 0.5);
        .el-range__icon {
          display: none;
        }
        .el-range-separator {
          color: #009aff;
        }
        .el-range-input {
          height: 36px;
          box-sizing: border-box;
          /* background: rgba(12, 34, 67, 0.5); */
          background: transparent;
          /* border: 1px solid #009aff; */
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
          width: 132px;
          line-height: 36px;
        }
        .el-range__close-icon {
          line-height: 36px;
        }
      }

      .el-input {
        box-sizing: border-box;
        .el-input__inner {
          height: 36px;
          box-sizing: border-box;
          background: rgba(12, 34, 67, 0.5);
          border: 1px solid #009aff;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
          width: 132px;
          line-height: 36px;
        }
        .el-input__suffix {
          .el-input__suffix-inner {
            .el-input__icon,
            .el-input__clear {
              line-height: 36px;
            }
          }
        }
      }
    }
  }
  &.vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .el-form-item {
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
      .el-form-item__label {
        font-size: 14px;
        color: #9fc4ff;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        display: block;
        flex-shrink: 0;
        float: none;
        text-align: left;
      }
      .el-form-item__content {
        line-height: 1;
        box-sizing: border-box;

        .el-textarea {
          textarea {
            width: 100%;
          }
        }

        .el-date-editor {
          width: 100%;
          .el-range-input {
            width: calc((100% - 56px) / 2);
          }
        }

        .el-select {
          width: 100%;
        }
        .el-input {
          box-sizing: border-box;
          width: 100%;
          .el-input__inner {
            width: 100%;
          }
        }
      }
    }
  }
  .form-submit-item {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
}
</style>

<style lang="scss" scoped>
.cpt-platform-layout {
  height: 100%;
  overflow: hidden;
  background-image: url(~@/assets/platform/grid-bg.png),
    linear-gradient(90deg, #041e47 0%, #0f3e7a 100%);
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  padding-top: 90px;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    .cesium-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-sizing: border-box;
      margin: 15px 8px;
      background: center center url(~@/assets/platform/bg-border.png) no-repeat;
      background-size: 100% 100%;
      padding: 15px 11px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      .cesium-wrap {
        flex: 1;
        position: relative;
      }
    }
  }
}
</style>
<style lang="scss">
.task-list-notice-wrap {
  display: flex;
  .map-box {
    width: 436px;
    height: 274px;
    border: 1px solid red;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-right: 16px;
  }
  .props-box {
    flex: 1;
    box-sizing: border-box;
    .prop-field {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
      .prop-field-k {
        font-size: 14px;
        color: #cce8ff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
        width: 98px;
      }
      .prop-field-v {
        font-size: 14px;
        color: #cce8ff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>